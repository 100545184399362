::selection {
  background-color:#bd1a38;
  color: rgba(251,251,251,1);
}
body {
  background-color: #ededed;
}
.ui.card {
  background-color:#fbfbfb;
}
.glide__slide {
  padding: 20px;
}
.glide__arrow {
  padding: 0px;
  margin: 0px;
  border: 0px;
  background-color: #fbfbfb;
  position: relative;
  top: unset;
  transform: none;
}
.glide__arrow--left {
  left: 0em;
}
.glide__arrow--right {
  right: 0em;
}
.glide__arrow--right > i.icon {
  margin: 0 0 0 .25rem;
}
.glide__arrow--left > i.icon {
  margin: 0 .25rem 0 0;
}
.glide__arrow i.icon{
  font-size: 2em;
  color: #006085;
}
ul.inline-list {
  list-style-type: none;
  padding: 0;
}
ul.inline-list li {
  display: inline;
}
#main {
  padding-top:75px;
  //margin-top:-75px;
}
.invenio-page-body {
  margin-top:-75px;
  padding-top:75px;
}
#frontpage-hero {
  background-color: #006085;
  margin-top:-75px !important;
  padding-top:125px; // ok
  padding-bottom:100px; // ok
}
#frontpage-hero p,
#frontpage-hero h3,
#frontpage-hero h1,
#frontpage-hero h2,
#frontpage-hero h4 {
  color:#fbfbfb;
}

#frontpage-hero .drukm-header h1 {
  font-size: 3.6rem;
}

#frontpage-hero .drukm-header p {
  font-size: 1.2rem;
}

#frontpage-hero a {
  text-decoration: none;
}
#frontpage-hero a:hover {
  background-color: #bd1a38;
}

#frontpage-search-bar {
  //background-color: #006085;
  padding-bottom: 75px;
  padding-top: 75px;
}

.theme.header {
  position: fixed;
  width: 100%;
  z-index: 999;
  box-shadow: unset;
}

.frontpage .outer-navbar {
  background-color: #006085;
  backdrop-filter: none;
  transition: background-color 0.3s ease, backdrop-filter 0.3s ease;
}
.outer-navbar,
.frontpage .outer-navbar.scrolled {
    //background-color: rgba(251,251,251,0.8);
    background-color: rgba(237,237,237,0.8);
    backdrop-filter: blur(12px);
    transition: background-color 0.3s ease, backdrop-filter 0.3s ease;
}
#frontpage-slider {
  //background-image: url('/home/ubuntu1admin/drukm/invenio-static-drukm/images/bg.jpg');
  //background-size: cover;
  padding-top: 75px;
  padding-bottom: 75px;
}

#frontpage-slider .glide .ui.card {
  width: unset !important;
  -webkit-box-shadow: 0px 0px 10px 10px rgba(50,50,50,0.1);
  -moz-box-shadow: 0px 0px 10px 10px rgba(50,50,50,0.1);
  box-shadow: 0px 0px 10px 10px rgba(50,50,50,0.1);
}

#frontpage-sum {
  background-image: url('/home/ubuntu1admin/drukm/invenio-static-drukm/images/bg.jpg');
  background-size: cover;
  padding-top: 75px;
  padding-bottom: 75px;
  position:relative; 
  z-index: 0;
}

.frontpage-sum-bg-layer {
  position: absolute;
  background: rgb(237,237,237);
  background: linear-gradient(180deg, rgba(237,237,237,1) 0%, rgba(237,237,237,0.7475784259016107) 50%, rgba(237,237,237,0.4982787060136554) 100%);
  top: 0; 
  left: 0; 
  width: 100%; 
  height: 100%; 
  z-index: 1;
}
